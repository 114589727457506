<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> {{ title }} </v-card-title>
          <v-form ref="form">
            <v-card-text class="py-0">
              <v-container v-if="isLoading">
                <v-row>
                  <v-col>
                    <v-progress-linear
                      indeterminate
                      color="msaBlue"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="pb-0 px-0 mt-4" fluid v-show="!isLoading">
                <v-row>
                  <v-col v-if="isBasedOnTemplate">
                    <v-alert dense type="info" class="text-caption">
                      This task is based on a template. To edit the name or
                      attached resource you should edit the template.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row dense v-if="!isNew">
                  <v-col cols="12"> Status </v-col>
                  <v-col>
                    <v-btn-toggle v-model="task.isDone" mandatory dense>
                      <v-btn
                        style="width: 173px"
                        class="text-capitalize"
                        :value="0"
                        active-class="orange--text"
                      >
                        Outstanding
                      </v-btn>
                      <v-btn
                        style="width: 173px"
                        class="text-capitalize"
                        :value="1"
                        active-class="green--text"
                      >
                        Completed
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" v-if="attachedResourceLabel">
                    <strong>
                      {{ attachedResourceLabel }}
                    </strong>
                  </v-col>
                  <v-col cols="12" v-if="task.resourceName">
                    {{ task.resourceName }}
                  </v-col>
                  <v-col cols="12">
                    <v-row class="mt-4 mb-2" v-if="!isBasedOnTemplate">
                      <v-btn
                        v-if="showRemoveButton"
                        color="red"
                        dark
                        class="ml-2"
                        @click="removeResource"
                      >
                        Remove
                      </v-btn>
                      <DocumentSelectorDialog
                        class="ml-2"
                        :buttonLabel="documentButtonLabel"
                        @select="onDocumentSelect"
                      ></DocumentSelectorDialog>
                      <CourseSelectorDialog
                        class="ml-2"
                        :buttonLabel="courseButtonLabel"
                        @select="onCourseSelect"
                      >
                      </CourseSelectorDialog>
                    </v-row>
                  </v-col>
                  <v-col cols="12"> Task Name </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="py-0"
                      dense
                      required
                      counter
                      maxlength="255"
                      outlined
                      v-model="task.name"
                      :rules="[rules.name, rules.required]"
                      :disabled="isBasedOnTemplate"
                      data-testid="task-name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12"> Due Date </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="py-0"
                      dense
                      outlined
                      label="yyyy-mm-dd"
                      v-model="task.dueDate"
                      :rules="[rules.stringDate, rules.validateDateRange]"
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12"> Notes (optional)</v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="task.notes"
                      outlined
                      maxlength="255"
                      counter
                      dense
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row dense v-if="!isNew">
                  <v-col cols="12"> Last Update</v-col>
                  <v-col cols="12">
                    By {{ task.updater }} at
                    {{ task.updatedAt | filterAsLocalDate }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions class="px-0 mt-4" v-show="!isLoading">
            <v-container class="px-4" fluid>
              <v-row class="pb-4 px-3">
                <TaskDeleteDialog
                  v-if="!isNew"
                  :task="task"
                  label="Delete"
                  @deleted="gotoList"
                />
                <v-spacer></v-spacer>
                <v-btn class="mr-4 red--text" text @click="cancel">
                  Cancel
                </v-btn>
                <v-btn
                  class="msaBlue white--text"
                  :disabled="disableSave"
                  @click="validate()"
                >
                  Save
                </v-btn>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DocumentSelectorDialog from '@/components/TrainingTasks/DocumentSelectorDialog.vue';
import CourseSelectorDialog from '@/components/TrainingTasks/CourseSelectorDialog.vue';
import TaskDeleteDialog from '@/components/TrainingTasks/TaskDeleteDialog.vue';
import { DateTime } from 'luxon';

export default {
  name: 'TaskDetailPage',
  components: {
    DocumentSelectorDialog,
    CourseSelectorDialog,
    TaskDeleteDialog,
  },
  data() {
    return {
      isLoading: false,
      isOkayToProceed: false,
      task: {
        position: 1,
        taskGroupId: null,
        dueDate: null,
        updatedAt: null,
        resourceName: null,
        taskTypeId: this.$constants.TASK_TYPES.REGULAR,
        id: null,
        updater: null,
        resourceId: null,
        name: null,
        notes: null,
        isDone: 0,
      },
      original: {},
      rules: {
        name: (v) => {
          return !v || !!v.trim().length || 'This field is required.';
        },
        required: (v) => !!v || 'This field is required.',
        positiveInteger: (v) =>
          parseInt(v) > 0 || 'Field needs to be a positive number.',
        stringDate: (v) => this.$helpers.isValidDate(v) || 'Invalid date.',
        validateDateRange: (v) => this.validateDateRange(v),
      },
    };
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.task) === JSON.stringify(this.original) ||
        !this.task.name ||
        this.task.name.trim().length == 0 ||
        !this.$helpers.isValidDate(this.task.dueDate)
      );
    },
    title() {
      if (this.isNew) {
        return 'Add Task';
      }
      return 'Task Details';
    },
    isNew() {
      return this.$route.name == 'UserTaskNew';
    },
    isBasedOnTemplate() {
      if (!this.isNew && this.task.taskTemplateId != '') {
        return true;
      }
      return false;
    },
    attachedResourceLabel() {
      let label = null;
      switch (this.task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          label = 'Attached Document: ';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          label = 'Attached Training: ';
          break;
        default:
          label = null;
      }
      return label;
    },
    showRemoveButton() {
      return this.task.taskTypeId != this.$constants.TASK_TYPES.REGULAR;
    },
    documentButtonLabel() {
      let label = 'Attach Document';
      if (this.task.taskTypeId == this.$constants.TASK_TYPES.DOCUMENT) {
        label = 'Replace Document';
      }
      return label;
    },
    courseButtonLabel() {
      let label = 'Attach Course';
      if (this.task.taskTypeId == this.$constants.TASK_TYPES.TRAINING) {
        label = 'Replace Course';
      }
      return label;
    },
  },
  methods: {
    validateDateRange(v) {
      const _date = v || '';

      if (_date == '') {
        return true;
      }

      // Prevents error when date was equal to or earlier than 1752-01-01
      const earliestAllowedDate = DateTime.fromISO(
        this.$constants.DATE_SELECTOR_RANGE.MIN,
      );
      const date = DateTime.fromISO(_date);

      if (!date.isValid || date < earliestAllowedDate) {
        return 'Invalid date';
      }

      return true;
    },
    onDocumentSelect(doc) {
      this.task.taskTypeId = this.$constants.TASK_TYPES.DOCUMENT;
      this.task.resourceId = doc.id;
      this.task.resourceName = doc.originalFilename;

      // Resets button to Outstanding
      this.task.isDone = 0;

      if (!this.task.name || !this.task.name.length) {
        this.task.name = `Review ${doc.name}`;
      }
    },
    gotoList() {
      this.$router.push({ name: 'UserTasks' });
    },
    cancel() {
      this.gotoList();
    },
    onCourseSelect(course) {
      this.task.taskTypeId = this.$constants.TASK_TYPES.TRAINING;
      this.task.resourceId = course.id;
      this.task.resourceName = course.name;

      // Resets button to Outstanding
      this.task.isDone = 0;

      if (!this.task.name || !this.task.name.length) {
        this.task.name = `Complete ${course.name}`;
      }
    },
    removeResource() {
      this.task.resourceName = '';
      this.task.resourceId = 0;
      this.task.taskTypeId = this.$constants.TASK_TYPES.REGULAR;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      if (
        this.task.taskTypeId != this.$constants.TASK_TYPES.REGULAR &&
        this.task.isDone &&
        !this.isOkayToProceed &&
        !this.isNew
      ) {
        this.$root.showMessage(
          'Warning',
          'Completing a task with an attached item will not affect the attached item. Are you sure you want to proceed?',
          () => {
            this.isOkayToProceed = true;
            this.save();
          },
          () => {},
          'Confirm',
          'Cancel',
        );
        return;
      }

      let url = this.isNew ? 'add-task?format=json' : 'update-task?format=json';

      const params = {
        task: this.task,
        loaderText: 'Saving...',
      };
      this.$axios.post(url, params).then(() => {
        this.$router.push({
          name: 'UserTasks',
        });
      });
    },
    setData(data) {
      this.task = data;
      this.original = JSON.parse(JSON.stringify(this.task));
      this.$store.commit('setSelectedTaskName', this.task.name);
    },
    getTask() {
      this.isLoading = true;
      const params = {
        taskId: this.$route.params.taskId,
      };
      this.$axios
        .post('get-task?format=json', params)
        .then((response) => {
          this.isLoading = false;
          this.setData(response.data);
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$router.push({ name: 'UserTasks' });
          }
        });
    },
    loadUrlParams() {
      if (this.isNew) {
        this.$store.commit('setSelectedTaskName', '');
        this.task.taskGroupId = this.$route.params.groupId;
      } else {
        if (this.$route.params.taskId) {
          if (isNaN(parseInt(this.$route.params.taskId))) {
            this.$router.push({ name: 'UserTasks' });
          }
          this.getTask();
        }
      }
    },
  },
  beforeMount() {
    this.loadUrlParams();
  },
};
</script>
